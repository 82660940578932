import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "my-8" }, [
      _c("div", { staticClass: "pb-10" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _vm._v(_vm._s(_vm.$stringConstants("teamJobTimeRecordsTitle"))),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobTimeRecordsSubTitle")) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
        },
        [
          _c("div", { staticClass: "jb-operators-list-head" }, [
            _c("div", { staticClass: "ib width-perc-4" }),
            _c("div", { staticClass: "ib width-perc-20" }, [
              _vm._v(_vm._s(_vm.$stringConstants("startDateAndTime"))),
            ]),
            _c("div", { staticClass: "ib width-perc-20" }, [
              _vm._v(_vm._s(_vm.$stringConstants("endDateAndTime"))),
            ]),
            _c("div", { staticClass: "ib width-perc-35" }, [
              _c("div", { staticClass: "ib ib width-perc-33" }, [
                _vm._v(_vm._s(_vm.$stringConstants("travelTime"))),
              ]),
              _c("div", { staticClass: "ib ib width-perc-33" }, [
                _vm._v(_vm._s(_vm.$stringConstants("breakTime"))),
              ]),
              _c("div", { staticClass: "ib ib width-perc-33" }, [
                _vm._v(_vm._s(_vm.$stringConstants("downTime"))),
              ]),
            ]),
            _c("div", { staticClass: "ib width-perc-10 text-center" }, [
              _vm._v(_vm._s(_vm.$stringConstants("workTime"))),
            ]),
            _c("div", { staticClass: "ib width-perc-10 text-center" }, [
              _vm._v(_vm._s(_vm.$stringConstants("totalTime"))),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
          attrs: { id: "jb-add-operators-table" },
        },
        _vm._l(_vm.teamJobTimeDetailsList, function (timeRecords, idx) {
          return _c(
            "div",
            {
              key: idx,
              staticClass: "jb-add-operator-cont",
              class: _vm.teamJobDetails.isApprovedForInvoicing
                ? "unselectable"
                : "",
            },
            [
              _c("div", { staticClass: "ib width-perc-4 pt-4 pb-4" }, [
                _c("div", { staticClass: "jb-loc-icn" }, [
                  _vm._v(" " + _vm._s(idx + 1) + " "),
                ]),
              ]),
              _c("div", { staticClass: "ib width-perc-20 pt-4 pb-4" }, [
                _c(
                  "div",
                  { staticClass: "width-perc-85" },
                  [
                    _c("VueCtkDateTimePicker", {
                      class: { sdDate: _vm.isDetailsView },
                      attrs: {
                        color: "#1d34ad",
                        formatted: "DD-MM-YYYY HH:mm",
                        format: "YYYY-MM-DD HH:mm",
                        "no-label": "",
                        noClearButton: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.onChangeStartEndTime(idx)
                        },
                      },
                      model: {
                        value: timeRecords.startTime,
                        callback: function ($$v) {
                          _vm.$set(timeRecords, "startTime", $$v)
                        },
                        expression: "timeRecords.startTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "ib width-perc-20" }, [
                _c(
                  "div",
                  { staticClass: "width-perc-85" },
                  [
                    _c("VueCtkDateTimePicker", {
                      class: { sdDate: _vm.isDetailsView },
                      attrs: {
                        color: "#1d34ad",
                        formatted: "DD-MM-YYYY HH:mm",
                        format: "YYYY-MM-DD HH:mm",
                        "no-label": "",
                        noClearButton: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.onChangeStartEndTime(idx)
                        },
                      },
                      model: {
                        value: timeRecords.endTime,
                        callback: function ($$v) {
                          _vm.$set(timeRecords, "endTime", $$v)
                        },
                        expression: "timeRecords.endTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "ib width-perc-35" }, [
                _c(
                  "div",
                  { staticClass: "ib width-perc-33 pt-4 pb-4" },
                  [
                    _c("vue-timepicker", {
                      attrs: {
                        format: "HH:mm",
                        "hide-clear-button": "",
                        "hour-label": "Hours",
                        "minute-label": "Minutes",
                      },
                      on: {
                        open: function ($event) {
                          return _vm.onChangeTimeRecords()
                        },
                      },
                      model: {
                        value: timeRecords.travelTimeFormatted,
                        callback: function ($$v) {
                          _vm.$set(timeRecords, "travelTimeFormatted", $$v)
                        },
                        expression: "timeRecords.travelTimeFormatted",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-33 pt-4 pb-4" },
                  [
                    _c("vue-timepicker", {
                      attrs: {
                        format: "HH:mm",
                        "hide-clear-button": "",
                        "hour-label": "Hours",
                        "minute-label": "Minutes",
                      },
                      on: {
                        open: function ($event) {
                          return _vm.onChangeTimeRecords()
                        },
                        change: function ($event) {
                          return _vm.handleWorkTimingsManualUpdate(idx)
                        },
                      },
                      model: {
                        value: timeRecords.breakTimeFormatted,
                        callback: function ($$v) {
                          _vm.$set(timeRecords, "breakTimeFormatted", $$v)
                        },
                        expression: "timeRecords.breakTimeFormatted",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-33 pt-4 pb-4" },
                  [
                    _c("vue-timepicker", {
                      attrs: {
                        format: "HH:mm",
                        "hide-clear-button": "",
                        "hour-label": "Hours",
                        "minute-label": "Minutes",
                      },
                      on: {
                        open: function ($event) {
                          return _vm.onChangeTimeRecords()
                        },
                        change: function ($event) {
                          return _vm.handleWorkTimingsManualUpdate(idx)
                        },
                      },
                      model: {
                        value: timeRecords.downTimeFormatted,
                        callback: function ($$v) {
                          _vm.$set(timeRecords, "downTimeFormatted", $$v)
                        },
                        expression: "timeRecords.downTimeFormatted",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "ib width-perc-10 text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getTimeInHoursAndMins(timeRecords.workTimeFormatted)
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "ib width-perc-10 text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getTimeInHoursAndMins(timeRecords.totalTimeFormatted)
                    ) +
                    " "
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        _vm._l(2, function (loadInfo, j) {
          return _c(
            "div",
            {
              key: j,
              staticClass: "px-7 py-3",
              staticStyle: { border: "0.5px solid #eee" },
            },
            [
              _c("div", [
                _c("div", { staticClass: "ib width-perc-22" }, [
                  _c(
                    "div",
                    { staticClass: "width-perc-85" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-22" }, [
                  _c(
                    "div",
                    { staticClass: "width-perc-85" },
                    [
                      _c(VSkeletonLoader, {
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-5" }),
                _c("div", { staticClass: "ib width-perc-35" }, [
                  _c(
                    "div",
                    { staticClass: "ib ib width-perc-33" },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "pr-4",
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib ib width-perc-33" },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "pr-4",
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ib ib width-perc-33" },
                    [
                      _c(VSkeletonLoader, {
                        staticClass: "pr-4",
                        attrs: { height: "30", type: "card" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ib width-perc-16 text-center" },
                  [
                    _c(VSkeletonLoader, {
                      staticClass: "px-4",
                      attrs: { height: "30", type: "card" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }