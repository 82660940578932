export var clientType = "ehbihva7A7hTrPQbUnw";
export var gAnalyticsKey = "G-SFZG56D9YP";
export var gMapsApiKey = process.env.NODE_ENV == 'production' ? 'AIzaSyBzGpM14YtMKtLYHsEw6ZMihGoEQkV5dYE' : 'AIzaSyBg07txODm-Ybgm-a3ySPlmJfTDghm_9n0';
export var esriApiKey = process.env.NODE_ENV == 'production' ? 'AAPKfc5d3e60bd7f49f684936818d2fb772cokEbdYPLN27glfB83nfOFY0h1E3Qz1xR5Ge-nHr3I4f98mnSlzalyfUZg-EYEHVI' : 'AAPKfc5d3e60bd7f49f684936818d2fb772cokEbdYPLN27glfB83nfOFY0h1E3Qz1xR5Ge-nHr3I4f98mnSlzalyfUZg-EYEHVI';
export var facebookPixelId = '3137813803119422';
export var webLocalHostUrl = 'localhost';
export var webStagingUrl = 'https://web.staging.ag-drive.com/';
export var webBetaUrl = 'https://web.beta.ag-drive.com/';
export var qbInvoiceTarget = 'https://app.qbo.intuit.com/app/invoices';
export var xeroInvoiceTarget = 'https://go.xero.com/AccountsReceivable/Search.aspx';
export var sageInvoiceTarget = 'https://accounts-extra.sageone.com/invoicing/sales_invoices/';
export var youtubeTutorialsLink = 'https://www.youtube.com/watch?v=iR_mYfDoV8g&list=PLQ3TpRx5ipsdmc39hihBpMaCyyu5Q0NzL';
export var defaultTimezoneRegion = 'Europe/London';
export var defaultLatitude = '53.9500';
export var defaultLongitude = '-1.0800';
export var defaultBusinessLatitude = 53.9500;
export var defaultBusinessLongitude = -1.0800;
export var defaultMapZoom = 12;
export var defaultMarkerZoom = 16;
export var defaultPolygonStrokeColor = "#D5B427";
export var defaultIconFillColor = "#D5B427";
export var defaultIconStrokeColor = "#ffffff";
export var defaultDateFormat = 'DD/MM/YYYY, HH:mm';
export var defaultPageLimitSizeProduction = 200;
export var defaultPageLimitSizeDebug = 10;
export var defaultPageOffsetSize = 0;
export var defaultPageLimitSize = process.env.NODE_ENV == 'production' ? defaultPageLimitSizeProduction : defaultPageLimitSizeDebug;
export var defaultPageLimitSizeImportCustomers = 100;
export var defaultPageLimitSizeCustomer = defaultPageLimitSizeProduction;
export var defaultPageLimitForJobsFields = 700;
export var defaultPageLimitForJobsExport = defaultPageLimitSizeProduction;
export var defaultPageLimitForTimesheetsExport = defaultPageLimitSizeProduction;
export var defaultPageSizeCalendarJobs = 500;
export var defaultPageListCount = 0;
export var defaultPageTotalRowCount = 0;
export var defaultPageRowCount = 0;
export var defaultVehicleLengthUnit = 'metre';
export var defaultVehicleWeightUnit = 'kg';
export var defaultImplementLengthUnit = 'metre';
export var defaultImplementWeightUnit = 'kg';
export var defaultMinMapZoom = 4;
export var defaultMaxMapZoom = 18;
export var defaultAdminCostInPounds = 10;
export var defaultMemberCostInPounds = 5;
export var defaultAmountFixedDigitCount = 2;
export var defaultImageDataMountTimeout = 3000;
export var defaultInvoiceLoaderTimeout = 500;
export var defaultSnapshotMinTimeout = 2000;
export var defaultIntegrationBufferTimeout = 3000;
export var defaultCardAddSuccessMessageTimeout = 5000;
export var defaultSearchTimeout = 800;
export var defaultDropdownSearchTimeout = 500;
export var defaultBusinessProfilePrefixLength = 3;
export var defaultBusinessProfilePrefixMinLength = 2;
export var defaultMapPadding = 20;
export var defaultLabelVisibilityZoomLevel = 14;
export var defaultZoomChangedEvent = 'zoom_changed';
export var defaultResendCountdown = 60;
export var defaultObjectIdentifier = 'id';
export var defaultListExportFormat = 'xls';
export var defaultTabSelectionTimeout = 500;
export var defaultScrollToPositionTimeout = 2000;
export var defaultScrollToPositionClickTimeout = 2500;
export var defaultInfoWindowTimeout = 5000;
export var defaultUserLocationUpdateTimeout = 30; //in seconds
export var defaultInvoicingTermsInDays = 7;
export var defaulSignupLoadingTimeout = 5000;
export var defaultDrawingManagerPolygonOptions = {
  clickable: true,
  editable: true,
  strokeColor: defaultPolygonStrokeColor,
  strokeOpacity: 0.7,
  strokeWeight: 5,
  fillColor: defaultPolygonStrokeColor,
  fillOpacity: 0.1
};
export var setVisible = true;
export var setNotVisible = false;
export var setEditable = true;
export var setNotEditable = false;
export var setDetailed = true;
export var setNotDetailed = false;
export var quickbookAccessStruct = {
  noAction: "NoAction",
  noQuickbookAccess: "NoQuickbookAccess",
  quickbookAccess: "QuickbookAccess"
};
export var xeroAccessStruct = {
  noAction: "NoAction",
  noXeroAccess: "NoXeroAccess",
  xeroAccess: "XeroAccess"
};
export var sageAccessStruct = {
  noAction: "NoAction",
  noSageAccess: "NoSageAccess",
  sageAccess: "SageAccess"
};
export var defaultCurrencySymbols = {
  'GBP': '£'
};
var defaultChartDataBarColor = '#b87333';
export var initialMonthlySubscriptionsChartData = [['Month', 'Amount', {
  role: 'style'
}], ['Jan', 0, defaultChartDataBarColor], ['Feb', 0, defaultChartDataBarColor], ['Mar', 0, defaultChartDataBarColor], ['Apr', 0, defaultChartDataBarColor], ['May', 0, defaultChartDataBarColor], ['Jun', 0, defaultChartDataBarColor], ['Jul', 0, defaultChartDataBarColor], ['Aug', 0, defaultChartDataBarColor], ['Sep', 0, defaultChartDataBarColor], ['Oct', 0, defaultChartDataBarColor], ['Nov', 0, defaultChartDataBarColor], ['Dec', 0, defaultChartDataBarColor]];
export var months = [{
  title: 'Jan',
  value: 1
}, {
  title: 'Feb',
  value: 2
}, {
  title: 'Mar',
  value: 3
}, {
  title: 'Apr',
  value: 4
}, {
  title: 'May',
  value: 5
}, {
  title: 'Jun',
  value: 6
}, {
  title: 'Jul',
  value: 7
}, {
  title: 'Aug',
  value: 8
}, {
  title: 'Sep',
  value: 9
}, {
  title: 'Oct',
  value: 10
}, {
  title: 'Nov',
  value: 11
}, {
  title: 'Dec',
  value: 12
}];
export var accessAllPermission = 'AccessAll';
export var allComponentsPermissions = {
  fieldRead: "ReadField",
  fieldCreate: "CreateField",
  fieldUpdate: "UpdateField",
  fieldDelete: "DeleteField",
  fieldArchive: "ArchiveField",
  fieldRestore: "RestoreField",
  sendContractorInvitation: "SendInvitation",
  sendFarmerInvitation: "SendInvitation",
  membersList: "ListMembers",
  memberRoleChange: "ChangeRoleMember",
  memberRead: "ReadMemberProfile",
  memberUpdate: "UpdateMemberProfile",
  memberRemove: "RemoveMember",
  memberSettingRead: "ReadMemberSetting",
  memberSettingUpdate: "UpdateMemberSetting",
  operationRead: "ReadOperation",
  operationCreate: "CreateOperation",
  operationUpdate: "UpdateOperation",
  operationDelete: "DeleteOperation",
  operationTypeRead: "ReadOperationType",
  operationTypeCreate: "OperationTypeCreate",
  operationTypeUpdate: "UpdateOperationType",
  operationTypeDelete: "DeleteOperationType",
  implementCreate: "CreateImplement",
  implementUpdate: "UpdateImplement",
  implementRead: "ReadImplement",
  implementDelete: "DeleteImplement",
  vehicleCreate: "CreateVehicle",
  vehicleUpdate: "UpdateVehicle",
  vehicleRead: "ReadVehicle",
  vehicleDelete: "DeleteVehicle",
  productCreate: "CreateProduct",
  productUpdate: "UpdateProduct",
  productRead: "ReadProduct",
  productDelete: "DeleteProduct",
  productArchive: "ArchiveProduct",
  productRestore: "RestoreProduct",
  createSelfJob: "CreateJob",
  createJob: "CreateJob",
  invitationSend: "SendInvitation",
  invitationListRead: "ReadInvitations",
  invitationAccept: "AcceptInvitation",
  invitationDelete: "DeleteInvitation",
  createContact: "CreateInternalFarmer",
  createInvoice: "CreateInvoice",
  readInvoice: "ReadInvoice",
  updateInvoice: "UpdateInvoice",
  markInvoiceAsOpen: "MarkInvoiceAsOpen",
  markInvoiceAsPaid: "MarkInvoiceAsPaid",
  uploadInvoiceToExternalService: "UploadInvoiceToExternalService",
  readPaymentServiceKey: "ReadPaymentServiceKey",
  addCard: "AddCard",
  deleteCard: "DeleteCard",
  readCard: "ReadCard",
  listCards: "ListCards",
  cardSetDefault: "CardSetDefault",
  createSubscription: "CreateSubscription",
  readSubscription: "ReadSubscription",
  updateSubscription: "UpdateSubscription",
  cancelSubscription: "CancelSubscription",
  createUserAsContact: "CreateUserAsContact"
};
export var minlengthFullName = 2;
export var minlengthFirstName = 2;
export var minlengthLastName = 2;
export var minlengthPhoneNumber = 6;
export var maxlengthPhoneNumber = 10;
export var minlengthCompanyName = 2;
export var minlengthPassword = 8;
export var maxlengthPassword = 32;
export var maxLengthShort1 = 50;
export var maxLengthShort2 = 100;
export var maxLengthLong1 = 200;
export var ownerId = 'ownerId';
export var ownerColor = 'colorCode';
export var searchTextQuery = 'SearchText';
export var offsetQuery = 'Offset';
export var limitQuery = 'Limit';
export var preferenceQuery = '&Preference=';
export var includeDefaultOperationsQuery = '&IncludeDefaults=true';
export var customerIdsQuery = 'CustomerIds';
export var devSuperAdminAccessCode = 'DEFAULT';
export var queryIdentifiers = 'Ids';
export var isScheduledQuery = 'IsScheduled';