import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
export default {
  name: "TeamJobsInstructions",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  methods: {
    onChangeInstruction: function onChangeInstruction() {
      this.enableTeamJobDiscard();
    }
  }
};