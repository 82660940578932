import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { getShortName } from '@/utils/helpers';
import OwnerAvatar from '@/components/shared/avatars/avatar.vue';
export default {
  name: "TeamJobsLocationMap",
  mixins: [TeamJobsComputed],
  components: {
    OwnerAvatar: OwnerAvatar
  },
  mounted: function mounted() {
    this.$store.dispatch('initializeTeamJobsLocationMap');
  },
  methods: {
    getOperationLocationsById: function getOperationLocationsById() {
      var _this = this;
      this.$store.dispatch('getOperatorLocationHistory', [this.jobResourceId, this.teamJobLocationSelectedOperatorId]).then(function (res) {
        _this.$store.dispatch('clearOperatorPolylines');
        _this.$store.dispatch('drawSelectedOperatorPolylines');
      });
    },
    filterLocationByOperator: function filterLocationByOperator() {
      this.operatorRealTimeLocation = [];
      this.selectedOperatorLocations = [];
      this.getOperationLocationsById();
    },
    getShortOperatorName: function getShortOperatorName(operatorName) {
      return getShortName(operatorName, 20);
    },
    toggleLocationsReduced: function toggleLocationsReduced() {
      this.getOperationLocationsById();
    }
  }
};