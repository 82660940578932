import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "pt-6": _vm.isDetailsView, "pt-8": !_vm.isDetailsView } },
    [
      _c("div", { staticClass: "pb-10" }, [
        _c("div", { staticClass: "tj-cp-title" }, [
          _vm._v(_vm._s(_vm.$stringConstants("teamJobInstructionsTitle"))),
        ]),
        _c("div", { staticClass: "tj-cp-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$stringConstants("teamJobInstructionsSubTitle")) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.teamJobsLoader,
              expression: "!teamJobsLoader",
            },
          ],
        },
        [
          _c(VTextarea, {
            attrs: {
              placeholder: _vm.$stringConstants("notePlaceholder"),
              required: "",
              outlined: "",
              readonly:
                _vm.teamJobsLoader ||
                !_vm.teamJobViewPreference.instructions.isEditable,
              rows: "3",
              dense: "",
            },
            on: { keydown: _vm.onChangeInstruction },
            model: {
              value: _vm.teamJobInstructions,
              callback: function ($$v) {
                _vm.teamJobInstructions = $$v
              },
              expression: "teamJobInstructions",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.teamJobsLoader,
              expression: "teamJobsLoader",
            },
          ],
        },
        [
          _c(VSkeletonLoader, {
            staticClass: "mb-8",
            attrs: { height: "70", type: "card" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }