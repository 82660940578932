import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-8" }, [
    _c("div", { staticClass: "pb-10" }, [
      _c("div", { staticClass: "tj-cp-title" }, [
        _vm._v(
          " " + _vm._s(_vm.$stringConstants("teamJobNoteAndPhotosTitle")) + " "
        ),
      ]),
      _c("div", { staticClass: "tj-cp-subtitle" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$stringConstants("teamJobNoteAndPhotosSubTitle")) +
            " "
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.teamJobsLoader,
            expression: "!teamJobsLoader",
          },
        ],
        attrs: { id: "jb-compl-text-area" },
      },
      [
        _c(VTextarea, {
          attrs: {
            placeholder: _vm.$stringConstants("notePlaceholder"),
            required: "",
            outlined: "",
            readonly:
              _vm.teamJobsLoader ||
              !_vm.teamJobViewPreference.completionInformation.isEditable,
            rows: "4",
            dense: "",
          },
          on: { input: _vm.onChangeNotes },
          model: {
            value: _vm.teamJobNotes,
            callback: function ($$v) {
              _vm.teamJobNotes = $$v
            },
            expression: "teamJobNotes",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.teamJobsLoader,
            expression: "!teamJobsLoader",
          },
        ],
        staticClass: "jb-fnsh-photos",
        staticStyle: { display: "flex", overflow: "auto" },
      },
      [
        _c(
          "div",
          { staticClass: "pl-4 pt-4", staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              { staticClass: "ib add_job_attach pr-4 position-relative" },
              [
                _c(VFileInput, {
                  staticClass: "file_input job-attach",
                  attrs: {
                    accept:
                      "image/png, image/jpeg, image/bmp, image/jpg, image/gif, image/webp, application/pdf",
                    "prepend-icon": "mdi-file-upload",
                    disabled:
                      !_vm.teamJobViewPreference.instructionPhotos.isEditable,
                    multiple: "",
                  },
                  on: { change: _vm.previewImage },
                  model: {
                    value: _vm.selectedImages,
                    callback: function ($$v) {
                      _vm.selectedImages = $$v
                    },
                    expression: "selectedImages",
                  },
                }),
              ],
              1
            ),
            _vm._l(_vm.teamJobAttachments, function (img, imgIdx) {
              return _c(
                "div",
                { key: imgIdx, staticClass: "ib add_job_attach " },
                [
                  img.purpose == _vm.JobAttachmentPurposeType.Completion
                    ? _c(
                        "div",
                        {
                          staticClass: "mr-4",
                          staticStyle: { width: "100px", position: "relative" },
                        },
                        [
                          img.type == _vm.JobAttachmentType.Pdf
                            ? _c("TeamJobsAttachmentsPdf", {
                                attrs: { "file-url": img.url },
                              })
                            : _c(VImg, {
                                staticClass: "cursor-pointer",
                                attrs: {
                                  "lazy-src": _vm.base64ImagePlaceholder,
                                  src: img.url,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showImage(img.url)
                                  },
                                },
                              }),
                          _c("div", { staticClass: "jb-img-cls-btn" }, [
                            _c(
                              "div",
                              {
                                staticClass: "jb-img-cls-btn-crs",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeSelectedImage(img)
                                  },
                                },
                              },
                              [_vm._v(" - ")]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.teamJobsLoader,
            expression: "teamJobsLoader",
          },
        ],
      },
      [
        _c(VSkeletonLoader, {
          staticClass: "mb-3",
          attrs: { height: "120", type: "card" },
        }),
        _c(VSkeletonLoader, {
          staticClass: "mb-4",
          attrs: { width: "100", height: "100", type: "card" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }