import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'invoicesModule': ['invoiceDraftPreviewDetails', 'invoiceJobsPreviewLoader'],
  'invoicesExternalModule': ['invoiceLoaderExternal', 'jobsListForInvoiceExternal', 'noExternalListData', 'selectedJobsForInvoiceExternal', 'jobsForInvoiceSearchTextExternal', 'selectedCustomerIdForInvoiceExternal', 'customersListForInvoiceExternal', 'customersSearchTextExternal', 'invoiceDraftPreviewDetailsExternal', 'anyLineItemInvalid', 'dueDateForInvoiceExternal', 'invoiceSelectedTabExternal', 'draftedInvoiceIdToOpenExternal', 'invoiceDateExternal', 'invoiceGenerationInProgressExternal', 'invoiceSendExternal', 'invoiceExternalLineItems', 'invoiceLineItemJobNumbersDistinctExternal', 'invoiceLineItemsJobIdsExternal', 'invoiceContactCompanyName', 'invoiceExternalLineItemSearchText', 'invoicesListExternal', 'enableExternalInvoicingExternal', 'totalExternalInvoicesCount', 'invoicesExternalListCount', 'invoicesExternalShowAddLineItemView', 'invoicesExternalShowAddLineItemButton', 'invoicesExternalInitialLineItem', 'invoiceDraftPreviewDetailsExternalLineItems', 'fetchingLineItems', 'externalContactRadioGroup', 'invoiceSearchTextExternal', 'invoiceDateFilterApplied', 'tempJobId', 'customerForInvoicesList', 'customersFilterListForInvoicesList', 'customersFilterSearchText', 'customersListForInvoiceExternalSize', 'customersListForExternalInvoiceLoader', 'invoiceStatusEnum', 'supplierDetailsForExternalInvoice', 'customerDetailsForExternalInvoice'],
  'jobsInvoicesModule': ['invoiceJobReportUrls', 'invoiceReportGenerationInProgress', 'jobOperatorIds', 'jobOperatorLocations', 'invoiceReportUrlGenerationInProgress', 'generatedFromJobDetails'],
  'integrationsModule': ['xeroAccessState', 'xeroAccess', 'quickBookAccess', 'quickbookAccessState', 'sageAccessState', 'sageAccess', 'integrationType', 'linkedContactToExternalService', 'contacts', 'contactSearchText', 'loadedContacts', 'intergrationHasData', 'fetchingintergrationDataProgress', 'customerLoader', 'externalContactIdToLink', 'allCustomersLoaded', 'loadingIntegration', 'tempIntegratedContacts'],
  'root': ['noData', 'filtersApplied', 'maxCompanyNameLength', 'listCount']
};
export default {
  name: 'InvoicesExternalComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    getSenderId: function getSenderId() {
      return localStorage.getItem(ConstantValues.ownerId);
    },
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    }
  })
};