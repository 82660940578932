import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { TeamJobOperation } from '@/data/models/TeamJobOperation';
import { TeamJobViewPreference } from '@/data/models/TeamJobViewPreference';
import { UserRoleType } from '@/enum/userRoleType';
import { JobOwnerTypeText } from '@/enum/jobOwnerTypeText';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { listContainsItem } from '@/utils/helpers';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { TeamJobField } from '@/data/models/TeamJobField';
import { MinimalUser } from '@/data/models/MinimalUser';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import { TeamJobAddressSelectionType } from '@/enum/jobAddressSelectionType';
import { Machine } from '@/data/models/Machine';
import { TeamJobOperatorLocation } from '@/data/models/TeamJobOperatorLocation';
import { MachineType } from '@/enum/machineType';
import ConstantValues from '@/plugins/constantValues';
export var state = {
  teamJobsLoader: false,
  teamJobsAddLoader: false,
  teamJobViewPreference: new TeamJobViewPreference(),
  teamJobOwnerType: UserRoleType.Farmer,
  teamJobOwnerTypeText: JobOwnerTypeText,
  teamJobDetailsRealtime: null,
  teamJobDetailsUpdatedSnackbarVisible: false,
  teamJobDisabledAllResources: false,
  //operation
  teamJobOperation: new TeamJobOperation(),
  teamJobOperationsList: [],
  teamJobOperationsSearchText: null,
  teamJobOperationOwnerId: null,
  teamJobOperationsListSize: 0,
  teamJobOperationsListLoader: false,
  //customers
  teamJobUsersList: [],
  teamJobUserSearchText: null,
  teamJobUser: null,
  teamJobUsersListSize: 0,
  teamJobUsersListLoader: false,
  //contractor
  teamJobContractorId: null,
  //generic
  teamJobScheduledDate: null,
  teamJobStatus: TeamJobStatusType.Pending,
  teamJobInstructions: null,
  teamJobAttachments: [],
  //operators
  teamJobShowJobAddOperatorExpandedView: true,
  teamJobShowOperatorSelectionDropdown: false,
  teamJobOperators: [],
  teamJobVehiclesListForOperators: [],
  teamJobImplementsListForOperators: [],
  teamJobVehiclesSearchInputListForOperators: [],
  teamJobImplementsSearchInputListForOperators: [],
  teamJobOperatorToAdd: null,
  teamJobOperatorToAddLoader: false,
  teamJobTeamMembersForOperatorsSeachText: null,
  teamJobsTeamMembersListForOperators: [],
  teamJobVehiclesSearchTextForOperators: null,
  teamJobImplementsSearchTextForOperators: null,
  teamJobVehiclesListForOperatorsSize: 0,
  teamJobImplementsListForOperatorsSize: 0,
  teamJobVehiclesListForOperatorsLoader: false,
  teamJobImplementsListForOperatorsLoader: false,
  //products
  teamJobProducts: new Array(),
  teamJobProductToAdd: new TeamJobProduct(),
  teamJobProductsList: [],
  teamJobProductsSearchText: null,
  teamJobProductsLoader: false,
  teamJobShowJobAddProductsExpandedView: true,
  teamJobShowJobAddProductsDropdown: false,
  //fields
  teamJobFieldsTempList: new Array(),
  teamJobFieldsList: new Array(),
  teamJobFieldsCustomerSearchText: null,
  teamJobFieldsLoaded: 0,
  teamJobFieldsTotal: 0,
  teamJobFieldsOffset: 0,
  teamJobSelectedFields: [],
  teamJobSelectedFieldsForList: [],
  teamJobPolygons: [],
  teamJobSelectedPolygon: [],
  teamJobFieldLabels: [],
  teamJobFieldCustomerId: null,
  fieldRequesterId: null,
  //meta data
  teamJobMetaDataList: [],
  //addresses
  teamJobAddresses: [],
  teamJobFarmAddressesSelected: [],
  teamJobAddressInstructions: null,
  teamJobAllAddresses: [],
  teamJobAddressSelectionType: TeamJobAddressSelectionType.FarmAddress,
  teamJobAddressesDropPinLatitude: 0,
  teamJobAddressesDropPinLongitude: 0,
  teamJobDetails: null,
  teamJobCustomAddress: new TeamJobAddress(),
  //time details
  teamJobTimeDetailsList: [],
  teamJobTempTimeDetailsList: [],
  //location map
  teamJobLocationSelectedOperatorId: null,
  selectedOperatorLocations: [],
  operatorsMarkers: [],
  operatorRealTimeLocation: [],
  //job completion notes
  teamJobNotes: null,
  attachmentDeleteInProgress: false,
  //changes State
  hasTeamJobChangesOnDetails: false,
  //job invoicing
  teamJobInvoiced: false,
  teamJobsFieldMap: null,
  polygonShapes: [],
  jobsReportApiKey: process.env.VUE_APP_JOBS_REPORT_API_KEY
};
export var getters = {
  teamJobsLoader: function teamJobsLoader(state) {
    return state.teamJobsLoader;
  },
  teamJobsAddLoader: function teamJobsAddLoader(state) {
    return state.teamJobsAddLoader;
  },
  teamJobViewPreference: function teamJobViewPreference(state) {
    return state.teamJobViewPreference;
  },
  teamJobOwnerType: function teamJobOwnerType(state) {
    return state.teamJobOwnerType;
  },
  teamJobOwnerTypeText: function teamJobOwnerTypeText(state) {
    return state.teamJobOwnerTypeText;
  },
  teamJobDetailsRealtime: function teamJobDetailsRealtime(state) {
    return state.teamJobDetailsRealtime;
  },
  teamJobDetailsUpdatedSnackbarVisible: function teamJobDetailsUpdatedSnackbarVisible(state) {
    return state.teamJobDetailsUpdatedSnackbarVisible;
  },
  teamJobDisabledAllResources: function teamJobDisabledAllResources(state) {
    return state.teamJobDisabledAllResources;
  },
  //customers
  teamJobUsersList: function teamJobUsersList(state) {
    return state.teamJobUsersList;
  },
  teamJobUserSearchText: function teamJobUserSearchText(state) {
    return state.teamJobUserSearchText;
  },
  teamJobUser: function teamJobUser(state) {
    return state.teamJobUser;
  },
  teamJobUsersListSize: function teamJobUsersListSize(state) {
    return state.teamJobUsersListSize;
  },
  teamJobUsersListLoader: function teamJobUsersListLoader(state) {
    return state.teamJobUsersListLoader;
  },
  //contractor
  teamJobContractorId: function teamJobContractorId(state) {
    return state.teamJobContractorId;
  },
  //operations
  teamJobOperation: function teamJobOperation(state) {
    return state.teamJobOperation;
  },
  teamJobOperationsList: function teamJobOperationsList(state) {
    return state.teamJobOperationsList;
  },
  teamJobOperationsSearchText: function teamJobOperationsSearchText(state) {
    return state.teamJobOperationsSearchText;
  },
  teamJobOperationOwnerId: function teamJobOperationOwnerId(state) {
    return state.teamJobOperationOwnerId;
  },
  teamJobOperationsListSize: function teamJobOperationsListSize(state) {
    return state.teamJobOperationsListSize;
  },
  teamJobOperationsListLoader: function teamJobOperationsListLoader(state) {
    return state.teamJobOperationsListLoader;
  },
  //generic
  teamJobScheduledDate: function teamJobScheduledDate(state) {
    return state.teamJobScheduledDate;
  },
  teamJobStatus: function teamJobStatus(state) {
    return state.teamJobStatus;
  },
  teamJobInstructions: function teamJobInstructions(state) {
    return state.teamJobInstructions;
  },
  teamJobAttachments: function teamJobAttachments(state) {
    return state.teamJobAttachments;
  },
  //operators
  teamJobOperators: function teamJobOperators(state) {
    return state.teamJobOperators;
  },
  teamJobVehiclesListForOperators: function teamJobVehiclesListForOperators(state) {
    return state.teamJobVehiclesListForOperators;
  },
  teamJobVehiclesSearchTextForOperators: function teamJobVehiclesSearchTextForOperators(state) {
    return state.teamJobVehiclesSearchTextForOperators;
  },
  teamJobImplementsListForOperators: function teamJobImplementsListForOperators(state) {
    return state.teamJobImplementsListForOperators;
  },
  teamJobImplementsSearchTextForOperators: function teamJobImplementsSearchTextForOperators(state) {
    return state.teamJobImplementsSearchTextForOperators;
  },
  teamJobVehiclesSearchInputListForOperators: function teamJobVehiclesSearchInputListForOperators(state) {
    return state.teamJobVehiclesSearchInputListForOperators;
  },
  teamJobImplementsSearchInputListForOperators: function teamJobImplementsSearchInputListForOperators(state) {
    return state.teamJobImplementsSearchInputListForOperators;
  },
  teamJobShowJobAddOperatorExpandedView: function teamJobShowJobAddOperatorExpandedView(state) {
    return state.teamJobShowJobAddOperatorExpandedView;
  },
  teamJobShowOperatorSelectionDropdown: function teamJobShowOperatorSelectionDropdown(state) {
    return state.teamJobShowOperatorSelectionDropdown;
  },
  teamJobOperatorToAdd: function teamJobOperatorToAdd(state) {
    return state.teamJobOperatorToAdd;
  },
  teamJobOperatorToAddLoader: function teamJobOperatorToAddLoader(state) {
    return state.teamJobOperatorToAddLoader;
  },
  teamJobTeamMembersForOperatorsSeachText: function teamJobTeamMembersForOperatorsSeachText(state) {
    return state.teamJobTeamMembersForOperatorsSeachText;
  },
  teamJobsTeamMembersListForOperators: function teamJobsTeamMembersListForOperators(state) {
    return state.teamJobsTeamMembersListForOperators;
  },
  teamJobVehiclesListForOperatorsSize: function teamJobVehiclesListForOperatorsSize(state) {
    return state.teamJobVehiclesListForOperatorsSize;
  },
  teamJobImplementsListForOperatorsSize: function teamJobImplementsListForOperatorsSize(state) {
    return state.teamJobImplementsListForOperatorsSize;
  },
  teamJobVehiclesListForOperatorsLoader: function teamJobVehiclesListForOperatorsLoader(state) {
    return state.teamJobVehiclesListForOperatorsLoader;
  },
  teamJobImplementsListForOperatorsLoader: function teamJobImplementsListForOperatorsLoader(state) {
    return state.teamJobImplementsListForOperatorsLoader;
  },
  //products
  teamJobProducts: function teamJobProducts(state) {
    return state.teamJobProducts;
  },
  teamJobProductToAdd: function teamJobProductToAdd(state) {
    return state.teamJobProductToAdd;
  },
  teamJobProductsList: function teamJobProductsList(state) {
    return state.teamJobProductsList;
  },
  teamJobProductsSearchText: function teamJobProductsSearchText(state) {
    return state.teamJobProductsSearchText;
  },
  teamJobProductsLoader: function teamJobProductsLoader(state) {
    return state.teamJobProductsLoader;
  },
  teamJobShowJobAddProductsExpandedView: function teamJobShowJobAddProductsExpandedView(state) {
    return state.teamJobShowJobAddProductsExpandedView;
  },
  teamJobShowJobAddProductsDropdown: function teamJobShowJobAddProductsDropdown(state) {
    return state.teamJobShowJobAddProductsDropdown;
  },
  //fields
  teamJobFieldsTempList: function teamJobFieldsTempList(state) {
    return state.teamJobFieldsTempList;
  },
  teamJobFieldsList: function teamJobFieldsList(state) {
    return state.teamJobFieldsList;
  },
  teamJobFieldsCustomerSearchText: function teamJobFieldsCustomerSearchText(state) {
    return state.teamJobFieldsCustomerSearchText;
  },
  teamJobFieldsLoaded: function teamJobFieldsLoaded(state) {
    return state.teamJobFieldsLoaded;
  },
  teamJobFieldsTotal: function teamJobFieldsTotal(state) {
    return state.teamJobFieldsTotal;
  },
  teamJobFieldsOffset: function teamJobFieldsOffset(state) {
    return state.teamJobFieldsOffset;
  },
  teamJobSelectedFields: function teamJobSelectedFields(state) {
    return state.teamJobSelectedFields;
  },
  teamJobSelectedFieldsForList: function teamJobSelectedFieldsForList(state) {
    return state.teamJobSelectedFieldsForList;
  },
  teamJobPolygons: function teamJobPolygons(state) {
    return state.teamJobPolygons;
  },
  teamJobSelectedPolygon: function teamJobSelectedPolygon(state) {
    return state.teamJobSelectedPolygon;
  },
  teamJobFieldLabels: function teamJobFieldLabels(state) {
    return state.teamJobFieldLabels;
  },
  teamJobFieldCustomerId: function teamJobFieldCustomerId(state) {
    return state.teamJobFieldCustomerId;
  },
  fieldRequesterId: function fieldRequesterId(state) {
    return state.fieldRequesterId;
  },
  //metaData
  teamJobMetaDataList: function teamJobMetaDataList(state) {
    return state.teamJobMetaDataList;
  },
  //addresses
  teamJobAddresses: function teamJobAddresses(state) {
    return state.teamJobAddresses;
  },
  teamJobFarmAddressesSelected: function teamJobFarmAddressesSelected(state) {
    return state.teamJobFarmAddressesSelected;
  },
  teamJobAddressInstructions: function teamJobAddressInstructions(state) {
    return state.teamJobAddressInstructions;
  },
  teamJobAllAddresses: function teamJobAllAddresses(state) {
    return state.teamJobAllAddresses;
  },
  teamJobAddressSelectionType: function teamJobAddressSelectionType(state) {
    return state.teamJobAddressSelectionType;
  },
  teamJobAddressesDropPinLatitude: function teamJobAddressesDropPinLatitude(state) {
    return state.teamJobAddressesDropPinLatitude;
  },
  teamJobAddressesDropPinLongitude: function teamJobAddressesDropPinLongitude(state) {
    return state.teamJobAddressesDropPinLongitude;
  },
  teamJobCustomAddress: function teamJobCustomAddress(state) {
    return state.teamJobCustomAddress;
  },
  //time Details
  teamJobTimeDetailsList: function teamJobTimeDetailsList(state) {
    return state.teamJobTimeDetailsList;
  },
  teamJobTempTimeDetailsList: function teamJobTempTimeDetailsList(state) {
    return state.teamJobTempTimeDetailsList;
  },
  teamJobDetails: function teamJobDetails(state) {
    return state.teamJobDetails;
  },
  //location map
  teamJobLocationSelectedOperatorId: function teamJobLocationSelectedOperatorId(state) {
    return state.teamJobLocationSelectedOperatorId;
  },
  selectedOperatorLocations: function selectedOperatorLocations(state) {
    return state.selectedOperatorLocations;
  },
  operatorsMarkers: function operatorsMarkers(state) {
    return state.operatorsMarkers;
  },
  operatorRealTimeLocation: function operatorRealTimeLocation(state) {
    return state.operatorRealTimeLocation;
  },
  //job completion
  teamJobNotes: function teamJobNotes(state) {
    return state.teamJobNotes;
  },
  attachmentDeleteInProgress: function attachmentDeleteInProgress(state) {
    return state.attachmentDeleteInProgress;
  },
  //changes states
  hasTeamJobChangesOnDetails: function hasTeamJobChangesOnDetails(state) {
    return state.hasTeamJobChangesOnDetails;
  },
  //invoice
  teamJobInvoiced: function teamJobInvoiced(state) {
    return state.teamJobInvoiced;
  }
};
export var mutations = {
  setTeamJobOperation: function setTeamJobOperation(state, data) {
    state.teamJobOperation = new TeamJobOperation().getTeamJobOperation(data);
  },
  setTeamJobViewPreference: function setTeamJobViewPreference(state, data) {
    state.teamJobViewPreference = new TeamJobViewPreference().getTeamJobViewPreference(data);
  },
  setTeamJobsAddressList: function setTeamJobsAddressList(state, data) {
    var addresses = data;
    if (addresses && addresses.length) {
      addresses.forEach(function (address) {
        state.teamJobAllAddresses.push(new TeamJobAddress().getTeamJobAddress(address));
      });
    }
  },
  setTeamJobsOwners: function setTeamJobsOwners(state, teamJobOwners) {
    var businessName = localStorage.getItem('fullOwnerBusinessName') + " (You)";
    if (!listContainsItem(state.teamJobUsersList, [ConstantValues.defaultObjectIdentifier], localStorage.getItem(ConstantValues.ownerId))) {
      state.teamJobUsersList.push({
        businessName: businessName,
        id: localStorage.getItem(ConstantValues.ownerId)
      });
    }
    if (teamJobOwners && teamJobOwners.length) {
      teamJobOwners.forEach(function (owner) {
        if (!listContainsItem(state.teamJobUsersList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
          if (owner.businessProfile) {
            state.teamJobUsersList.push(new MinimalUser().getMinimalUser(owner));
          }
        }
      });
    }
  },
  setTeamJobsOperations: function setTeamJobsOperations(state, operations) {
    if (operations && operations.length) {
      operations.forEach(function (operation) {
        if (!listContainsItem(state.teamJobOperationsList, [ConstantValues.defaultObjectIdentifier], operation.id)) state.teamJobOperationsList.push(new TeamJobOperation().getTeamJobOperation(operation));
      });
    }
  },
  setTeamJobVehiclesList: function setTeamJobVehiclesList(state, vehicles) {
    if (vehicles && vehicles.length) {
      vehicles.forEach(function (vehicle) {
        if (!listContainsItem(state.teamJobVehiclesListForOperators, [ConstantValues.defaultObjectIdentifier], vehicle.id)) {
          if (state.teamJobVehiclesListForOperators && state.teamJobVehiclesListForOperators.length && state.teamJobVehiclesListForOperators.length > vehicles.length) {
            state.teamJobVehiclesListForOperators.unshift(new Machine().getVehicle(vehicle));
          } else {
            state.teamJobVehiclesListForOperators.push(new Machine().getVehicle(vehicle));
          }
        }
      });
    }
  },
  setTeamJobOperatorMachinesDropdown: function setTeamJobOperatorMachinesDropdown(state, data) {
    var machineType = data.machineType,
      machinesList = data.machinesList;
    switch (machineType) {
      case MachineType.Vehicle:
        if (machinesList && machinesList.length) {
          machinesList.forEach(function (m) {
            if (!listContainsItem(state.teamJobVehiclesListForOperators, [ConstantValues.defaultObjectIdentifier], m.id)) state.teamJobVehiclesListForOperators.push(m);
          });
        }
        break;
      case MachineType.Implement:
        if (machinesList && machinesList.length) {
          machinesList.forEach(function (m) {
            if (!listContainsItem(state.teamJobImplementsListForOperators, [ConstantValues.defaultObjectIdentifier], m.id)) state.teamJobImplementsListForOperators.push(m);
          });
        }
        break;
      default:
        return;
    }
  },
  setTeamJobImplementsList: function setTeamJobImplementsList(state, implementsList) {
    if (implementsList && implementsList.length) {
      implementsList.forEach(function (implement) {
        if (!listContainsItem(state.teamJobImplementsListForOperators, [ConstantValues.defaultObjectIdentifier], implement.id)) {
          if (state.teamJobImplementsListForOperators && state.teamJobImplementsListForOperators.length && state.teamJobImplementsListForOperators.length > implementsList.length) {
            state.teamJobImplementsListForOperators.unshift(new Machine().getVehicle(implement));
          } else {
            state.teamJobImplementsListForOperators.push(new Machine().getVehicle(implement));
          }
        }
      });
    }
  },
  setProductsListForTeamsJob: function setProductsListForTeamsJob(state, data) {
    var products = data;
    if (products.length) {
      if (state.teamJobProductsList.length) {
        products.forEach(function (product) {
          if (!listContainsItem(state.teamJobProductsList, [ConstantValues.defaultObjectIdentifier], product.id)) state.teamJobProductsList.push(new TeamJobProduct().getTeamJobProduct(product));
        });
      } else {
        state.teamJobProductsList = products.map(function (product) {
          return new TeamJobProduct().getTeamJobProduct(product);
        });
      }
    }
  },
  setTeamJobAttachment: function setTeamJobAttachment(state, attachmentsList) {
    var result = attachmentsList.result,
      type = attachmentsList.type,
      purpose = attachmentsList.purpose;
    if (typeof result === 'string' || result instanceof String) {
      var obj = {
        "id": null,
        "purpose": purpose,
        "type": type,
        "url": result
      };
      state.teamJobAttachments.push(obj);
    } else if (result && Array.isArray(result) && result.length > 0) {
      result.map(function (attachment) {
        var obj = {
          "id": null,
          "purpose": purpose,
          "type": type,
          "url": attachment.path
        };
        state.teamJobAttachments.push(obj);
      });
    }
  },
  deleteAttachmentFromList: function deleteAttachmentFromList(state, url) {
    state.teamJobAttachments = state.teamJobAttachments.filter(function (x) {
      return x.url != url;
    });
  },
  setTeamMembersListForTeamsJob: function setTeamMembersListForTeamsJob(state, data) {
    // farmer's or contractor's manager logged in
    if (getLoggedInUserRole().isFarmersManagerLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
      if (!listContainsItem(state.teamJobsTeamMembersListForOperators, [ConstantValues.defaultObjectIdentifier], localStorage.getItem(ConstantValues.ownerId))) {
        state.teamJobsTeamMembersListForOperators.push({
          firstName: localStorage.getItem('fullOwnerName') + ' (owner)',
          id: localStorage.getItem(ConstantValues.ownerId)
        });
      }
    }
    // farmer or contractor logged in
    if ((getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isContractorLoggedIn) && data[1]) {
      if (!listContainsItem(state.teamJobsTeamMembersListForOperators, [ConstantValues.defaultObjectIdentifier], localStorage.getItem(ConstantValues.ownerId))) {
        state.teamJobsTeamMembersListForOperators.push({
          firstName: data[1] + ' (You)',
          id: localStorage.getItem(ConstantValues.ownerId)
        });
      }
    }
    data[0].map(function (data) {
      return state.teamJobsTeamMembersListForOperators.push(data);
    });
    state.teamJobsTeamMembersListForOperators.map(function (t, index) {
      if (getLoggedInUserRole().isContractorLoggedIn) {
        t.firstName = t.firstName + (t.lastName ? ' ' + t.lastName : '');
      } else {
        if (index != 0) {
          t.firstName = t.firstName + (t.lastName ? ' ' + t.lastName : '');
        }
      }
    });
  },
  setFieldsForTeamJobs: function setFieldsForTeamJobs(state, data) {
    if (data && data.length) {
      data.forEach(function (field) {
        state.teamJobFieldsList.push(new TeamJobField().getTeamJobField(field));
        state.teamJobFieldsTempList.push(new TeamJobField().getTeamJobField(field));
      });
    }
    state.teamJobFieldsLoaded = state.teamJobFieldsList.length;
    state.teamJobFieldsOffset = state.teamJobFieldsLoaded;
  },
  setTeamJobDetails: function setTeamJobDetails(state, data) {
    state.teamJobDetails = data;
  },
  setTeamJobsOperatorLocationHistory: function setTeamJobsOperatorLocationHistory(state, data) {
    if (data.operatorLocations.length > 0) {
      data.operatorLocations.forEach(function (location) {
        state.selectedOperatorLocations.push(new TeamJobOperatorLocation().getTeamJobOperatorLocation(location));
      });
    }
  },
  resetTeamJobs: function resetTeamJobs(state) {
    state.teamJobViewPreference = new TeamJobViewPreference();
    state.teamJobsLoader = false;
    state.teamJobsAddLoader = false;
    state.teamJobOwnerType = UserRoleType.Farmer;
    state.teamJobOwnerTypeText = JobOwnerTypeText;
    state.teamJobDetailsRealtime = null;
    state.teamJobDetailsUpdatedSnackbarVisible = false;
    state.teamJobDisabledAllResources = false;
    //operation
    state.teamJobOperation = new TeamJobOperation();
    state.teamJobOperationsList = [];
    state.teamJobOperationsSearchText = null;
    state.teamJobOperationOwnerId = null;
    state.teamJobOperationsListSize = 0;
    state.teamJobOperationsListLoader = false;
    //customers
    state.teamJobUsersList = [];
    state.teamJobUser = null;
    state.teamJobUserSearchText = null;
    state.teamJobUsersListSize = 0;
    state.teamJobUsersListLoader = false;
    //contractor
    state.teamJobContractorId = null;
    //generic
    state.teamJobScheduledDate = null;
    state.teamJobStatus = TeamJobStatusType.Pending;
    state.teamJobInstructions = null;
    state.teamJobAttachments = [];
    //operators
    state.teamJobShowJobAddOperatorExpandedView = true;
    state.teamJobShowOperatorSelectionDropdown = false;
    state.teamJobOperators = [];
    state.teamJobVehiclesListForOperators = [];
    state.teamJobImplementsListForOperators = [];
    state.teamJobVehiclesSearchInputListForOperators = [];
    state.teamJobImplementsSearchInputListForOperators = [];
    state.teamJobOperatorToAdd = null;
    state.teamJobOperatorToAddLoader = false;
    state.teamJobTeamMembersForOperatorsSeachText = null;
    state.teamJobsTeamMembersListForOperators = [];
    state.teamJobVehiclesSearchTextForOperators = null;
    state.teamJobImplementsSearchTextForOperators = null;
    state.teamJobVehiclesListForOperatorsSize = 0;
    state.teamJobImplementsListForOperatorsSize = 0;
    state.teamJobVehiclesListForOperatorsLoader = false;
    state.teamJobImplementsListForOperatorsLoader = false;
    //products
    state.teamJobProducts = [];
    state.teamJobProductToAdd = null;
    state.teamJobProductsList = [];
    state.teamJobProductsSearchText = null;
    state.teamJobProductsLoader = false;
    state.teamJobShowJobAddProductsExpandedView = true;
    state.teamJobShowJobAddProductsDropdown = false;
    //fields
    state.teamJobFieldsTempList = new Array();
    state.teamJobFieldsList = new Array();
    state.teamJobFieldsCustomerSearchText = null;
    state.teamJobFieldsLoaded = 0;
    state.teamJobFieldsTotal = 0;
    state.teamJobFieldsOffset = 0;
    state.teamJobSelectedFields = [];
    state.teamJobSelectedFieldsForList = [];
    state.teamJobPolygons = [];
    state.teamJobSelectedPolygon = [];
    state.teamJobFieldLabels = [];
    state.teamJobFieldCustomerId = null;
    state.fieldRequesterId = null;
    //meta data
    state.teamJobMetaDataList = [];
    //addresses
    state.teamJobAddresses = [];
    state.teamJobFarmAddressesSelected = [];
    state.teamJobAllAddresses = [];
    state.teamJobAddressInstructions = null;
    state.teamJobAddressSelectionType = TeamJobAddressSelectionType.FarmAddress;
    state.teamJobAddressesDropPinLatitude = 0;
    state.teamJobAddressesDropPinLongitude = 0;
    state.teamJobCustomAddress = new TeamJobAddress();
    //time details 
    state.teamJobTimeDetailsList = [];
    state.teamJobTempTimeDetailsList = [];
    state.teamJobDetails = null;
    //location map
    state.teamJobLocationSelectedOperatorId = null;
    state.selectedOperatorLocations = [];
    state.operatorsMarkers = [];
    state.operatorRealTimeLocation = [];
    //job completion
    state.teamJobNotes = null;
    state.attachmentDeleteInProgress = false;
    //changes state
    state.hasTeamJobChangesOnDetails = false;
    //invoice
    state.teamJobInvoiced = false;
    //Map
    state.teamJobsFieldMap = false;
    state.polygonShapes = [];
  }
};