import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.teamJobOperation != null
      ? _c(
          "div",
          { staticClass: "py-8" },
          [
            _c("div", { staticClass: "pb-10" }, [
              _c("div", { staticClass: "tj-cp-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobBillingTitle")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "tj-cp-subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobBillingSubTitle")) +
                    " "
                ),
              ]),
            ]),
            _vm._l(
              _vm.teamJobOperation.billingQuantities,
              function (oprQty, i) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.teamJobsLoader,
                        expression: "!teamJobsLoader",
                      },
                    ],
                    key: i,
                    staticClass: "px-7 py-3",
                    staticStyle: { border: "0.5px solid #eee" },
                  },
                  [
                    _c(VRow, { attrs: { col: "12" } }, [
                      _c("div", { staticClass: "width-perc-60" }, [
                        _c(
                          "div",
                          { staticClass: "section-sub-title pt-3 fw-6" },
                          [_vm._v(_vm._s(_vm.teamJobOperation.title))]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "width-perc-20 pr-1" },
                        [
                          _c(VTextField, {
                            staticClass: "hide_input_details",
                            class: { "light-input": _vm.isDetailsView },
                            attrs: {
                              outlined: "",
                              height: "48",
                              placeholder: _vm.$stringConstants("value"),
                              disabled:
                                !_vm.teamJobViewPreference.billingInformation
                                  .isEditable,
                              dense: "",
                              required: "",
                            },
                            on: { input: _vm.onBillingValueChange },
                            model: {
                              value: oprQty.billingQuantity,
                              callback: function ($$v) {
                                _vm.$set(oprQty, "billingQuantity", _vm._n($$v))
                              },
                              expression: "oprQty.billingQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "width-perc-20 pl-1" },
                        [
                          _c(VTextField, {
                            staticClass: "hide_input_details rd-lf-no",
                            class: { "dark-input": _vm.isDetailsView },
                            attrs: {
                              outlined: "",
                              height: "48",
                              dense: "",
                              disabled: "",
                              required: "",
                            },
                            on: { input: _vm.onBillingValueChange },
                            model: {
                              value: _vm.teamJobOperation.billingUnit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.teamJobOperation,
                                  "billingUnit",
                                  $$v
                                )
                              },
                              expression: "teamJobOperation.billingUnit",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.teamJobsLoader,
                    expression: "teamJobsLoader",
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "px-7 py-3",
                    staticStyle: {
                      border: "0.5px solid #eee",
                      "border-top": "hidden",
                    },
                  },
                  [
                    _c(VRow, { attrs: { col: "12" } }, [
                      _c("div", { staticClass: "width-perc-60" }, [
                        _c(
                          "div",
                          { staticClass: "section-sub-title pt-3 fw-6" },
                          [
                            _c(VSkeletonLoader, {
                              attrs: {
                                width: "200",
                                height: "30",
                                type: "card",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "width-perc-20 pr-1" },
                        [
                          _c(VSkeletonLoader, {
                            attrs: { height: "30", type: "card" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "width-perc-20 pl-1" },
                        [
                          _c(VSkeletonLoader, {
                            attrs: { height: "30", type: "card" },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "width-perc-5 text-right" }),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }