import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import { getPageParam } from '@/utils/helpers/jobs-helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'teamJobsModule': ['teamJobViewPreference', 'teamJobDetails', 'teamJobTimeDetailsList', 'teamJobTempTimeDetailsList', 'attachmentDeleteInProgress', 'teamJobsLoader', 'teamJobsAddLoader', 'hasTeamJobChangesOnDetails', 'teamJobInvoiced', 'teamJobDetailsRealtime', 'teamJobDetailsUpdatedSnackbarVisible', 'teamJobDisabledAllResources', 'isAllOperatorsSelected', 'teamJobSelectedOperators', 'teamJobSelectedOperatorsHours', 'isAllTimeRecordsSelected', 'teamJobSelectedTimeRecords', 'teamJobSelectedTimeRecordsHours', 'isLocationsReduced'],
  'jobsInvoicesModule': ['generatedFromJobDetails', 'invoiceReportGenerationInProgress'],
  'root': ['dashboardTitle', 'showImagePreview', 'tempImageUrl', 'loginUserRole', 'hasMoreData', 'teamJobDetailsUpdatedRealTime', 'globalCountriesList', 'isJobBeingAdded']
};
export default {
  name: 'TeamJobsGlobalComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    getSenderId: function getSenderId() {
      return getOwnerId();
    },
    loginOwnerIsContractor: function loginOwnerIsContractor() {
      return getLoggedInUserRole().isUserOwnerContractorLoggedIn;
    },
    isDisabled: function isDisabled() {
      if (this.teamJobUser && this.teamJobOperation && this.teamJobOperation.id) {
        return false;
      } else {
        return true;
      }
    },
    jobResourceId: {
      get: function get() {
        return getPageParam();
      }
    },
    isDetailsView: function isDetailsView() {
      if (getPageParam() != 'add' && !this.isJobBeingAdded) {
        return true;
      } else {
        return false;
      }
    },
    ownerRole: {
      get: function get() {
        return localStorage.getItem('ownerRole');
      }
    },
    isFarmerLogInAndExteranlJobAndJobStarted: function isFarmerLogInAndExteranlJobAndJobStarted() {
      return getLoggedInUserRole().isUserOwnerFarmerLoggedIn && this.teamJobDetails.customer.id != this.teamJobDetails.contractor.id && this.teamJobDetails.status >= TeamJobStatusType.InProgress;
    },
    isFarmerLogInAndSelfJobAndJobStarted: function isFarmerLogInAndSelfJobAndJobStarted() {
      return getLoggedInUserRole().isUserOwnerFarmerLoggedIn && this.teamJobDetails.customer.id == this.teamJobDetails.contractor.id && this.teamJobDetails.status >= TeamJobStatusType.InProgress;
    }
  })
};