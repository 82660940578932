import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "\n        field-list-map-container \n        ml-9 \n        " +
        (!_vm.showFieldsList
          ? "h-percent-100"
          : "default-field-list-map-height") +
        "\n        " +
        (!_vm.drawerOpen ? "mr-9" : "") +
        "\n        ",
    },
    [
      !_vm.drawerOpen
        ? _c("div", { staticClass: "fld-text-overlay position-absolute" }, [
            _vm.fieldsListMapLoader
              ? _c("span", [_vm._v("Loading ")])
              : _vm._e(),
            _vm.fieldsTotalCount > 0 || !_vm.fieldsListMapLoader
              ? _c("span", [_vm._v(_vm._s(_vm.fieldsTotalCount))])
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.fieldsText) + " "),
            _vm.fieldsListMapLoader ? _c("span", [_vm._v("...")]) : _vm._e(),
          ])
        : _vm.showEditableFields && _vm.fieldDetails.id
        ? _c(
            VRow,
            {
              staticClass: "position-relative",
              staticStyle: { "z-index": "1" },
              attrs: { "no-gutters": "", cols: "12" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "position-absolute",
                  staticStyle: { right: "40px", margin: "10px 20px" },
                },
                [
                  !_vm.editingAreaOnMap && !_vm.fieldReadonly
                    ? _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "pale_gray",
                          textValue: _vm.$stringConstants("listItemEdit"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.editingAreaOnMap = true
                          },
                        },
                      })
                    : _vm.editingAreaOnMap
                    ? _c(
                        VRow,
                        { attrs: { cols: "12", "no-gutters": "" } },
                        [
                          _c("Button", {
                            staticClass: "mx-3",
                            attrs: {
                              variant: "secondary",
                              color: "pale_gray",
                              textValue: "Clear map",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.clearAreaOnMap($event)
                              },
                            },
                          }),
                          !(
                            _vm.drawingManager &&
                            _vm.drawingManager.getDrawingMode()
                          )
                            ? _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  color: "pale_gray",
                                  textValue: "Cancel",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.editingAreaOnMap = false
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : !_vm.fieldDetails.id
        ? _c(
            VRow,
            {
              staticClass:
                "position-absolute align-items-center justify-space-between no-click",
              staticStyle: { "z-index": "1", width: "calc(100% - 60px)" },
              attrs: { cols: "12" },
            },
            [
              _vm.fieldsListForSearchReadOnly.length !== 0
                ? _c("FieldsSearchReadonly", {
                    staticClass: "ma-5",
                    staticStyle: { "pointer-events": "initial" },
                  })
                : _vm._e(),
              _vm.drawingManager
                ? _c("Button", {
                    style: { marginTop: "10px", pointerEvents: "initial" },
                    attrs: {
                      variant: "secondary",
                      color: "pale_gray",
                      textValue: "Clear",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.clearAreaOnMap($event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        staticClass: "field-list-map",
        attrs: { id: "field-list-map" },
      }),
      _vm.fieldsListMapLoader
        ? _c(VSkeletonLoader, {
            staticClass: "jobs-fields-loader position-absolute",
            attrs: { type: "image", loading: _vm.fieldsListMapLoader },
          })
        : _vm._e(),
      _c(
        VAlert,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.drawerOpen && _vm.mapAlertMessage,
              expression: "drawerOpen && mapAlertMessage",
            },
          ],
          staticClass: "alert-container",
          attrs: {
            dense: "",
            prominent: "",
            color: "#1d34ad",
            icon: "mdi-alert-outline",
            type: "warning",
          },
        },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(VCol, { staticClass: "grow" }, [
                _vm._v(" " + _vm._s(_vm.mapAlertMessage) + " "),
              ]),
              _c(
                VCol,
                { staticClass: "shrink pa-0" },
                [
                  _c("Button", {
                    attrs: {
                      variant: "tertiary",
                      outlined: "",
                      color: "white",
                      textValue: "Select owner",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.focusOnOwnerSelection($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }