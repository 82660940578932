import "core-js/modules/es.array.concat.js";
import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { getTimeDetailsFormatted } from "@/utils/helpers/time-details-helpers";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { getHoursAndMins, getZeroPadding } from "@/utils/helpers";
export default {
  name: "TeamJobsMetadata",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
    VueTimepicker: VueTimepicker
  },
  methods: {
    handleWorkTimingsManualUpdate: function handleWorkTimingsManualUpdate(idx) {
      var tempTimeRecord = Object.assign({}, this.teamJobTempTimeDetailsList[idx]);
      var timeRecord = this.teamJobTimeDetailsList[idx];
      var startingTime = moment(moment(timeRecord.startTime).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY hh:mm:ss");
      var endingTime = moment(moment(timeRecord.endTime).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY hh:mm:ss");
      var startTimeEndTimeDifferenceInSeconds = endingTime.diff(startingTime, "seconds");
      var totaldownTimeInSeconds = parseInt(timeRecord.downTimeFormatted.HH) * 3600 + parseInt(timeRecord.downTimeFormatted.mm) * 60;
      var totalBreakTimeInSeconds = parseInt(timeRecord.breakTimeFormatted.HH) * 3600 + parseInt(timeRecord.breakTimeFormatted.mm) * 60;
      var startEndTimeDifferenceInSeconds = startTimeEndTimeDifferenceInSeconds - (totaldownTimeInSeconds + totalBreakTimeInSeconds);
      if (startEndTimeDifferenceInSeconds > 0) {
        this.teamJobTimeDetailsList[idx].workTimeFormatted = startTimeEndTimeDifferenceInSeconds - totalBreakTimeInSeconds - totaldownTimeInSeconds;
        this.teamJobTimeDetailsList[idx].totalTimeFormatted = startTimeEndTimeDifferenceInSeconds;
      } else {
        this.teamJobTimeDetailsList[idx].startTime = tempTimeRecord.startTime;
        this.teamJobTimeDetailsList[idx].endTime = tempTimeRecord.endTime;
        this.teamJobTimeDetailsList[idx].travelTimeFormatted = getTimeDetailsFormatted(tempTimeRecord.travelTimeInSeconds);
        this.teamJobTimeDetailsList[idx].downTimeFormatted = getTimeDetailsFormatted(tempTimeRecord.downTimeInSeconds);
        this.teamJobTimeDetailsList[idx].breakTimeFormatted = getTimeDetailsFormatted(tempTimeRecord.breakTimeInSeconds);
        this.teamJobTimeDetailsList[idx].workTimeFormatted = tempTimeRecord.workTimeInSeconds;
      }
    },
    onChangeStartEndTime: function onChangeStartEndTime(idx) {
      this.onChangeTimeRecords();
      this.handleWorkTimingsManualUpdate(idx);
    },
    onChangeWorkTime: function onChangeWorkTime(idx) {
      var tempTimeRecord = Object.assign({}, this.teamJobTempTimeDetailsList[idx]);
      if (this.teamJobTimeDetailsList[idx].workTimeFormatted != tempTimeRecord.workTimeFormatted) {
        this.enableTeamJobDiscard();
      }
    },
    onChangeTimeRecords: function onChangeTimeRecords() {
      this.enableTeamJobDiscard();
    },
    getTimeInHoursAndMins: function getTimeInHoursAndMins(timeDurationInSeconds) {
      var _getHoursAndMins = getHoursAndMins(timeDurationInSeconds),
        hours = _getHoursAndMins.hours,
        minutes = _getHoursAndMins.minutes;
      return "".concat(getZeroPadding(hours, 2), ":").concat(getZeroPadding(minutes, 2));
    }
  }
};